import React, { FC, ReactNode } from 'react';

import { CONNECTIONS } from '../../data/connections';
import { PRODUCT_LISTS } from '../../data/products';
import { COMPANY_LINKS, FOOTER_RESOURCES_LIST } from '../../data/resources';
import Button from '../Button';
import { Linkedin, Twitter } from '../Icons';
import Logo from '../Logo';
import Section from '../Section';

const ListItem: FC<{
  asPath?: string;
  children: ReactNode;
  href?: string;
  onClick?: () => void;
}> = ({ children, href, asPath, onClick }) => {
  return (
    <li className="inline text-left">
      <Button
        className="w-auto !block !text-left"
        variant="ghost"
        size="sm"
        asPath={asPath}
        href={href}
        onClick={onClick}
      >
        {children}
      </Button>
    </li>
  );
};

const ListTitle: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="text-gray-600 dark:text-bliss dark:opacity-80 mb-4 mx-3 text-sm sm:text-base">
    {children}
  </div>
);

const List: FC<{ children: ReactNode }> = ({ children }) => (
  <div>{children}</div>
);

const FooterNew: FC = () => {
  return (
    <footer className="dark:bg-cobalt-100 border-t-[0.5px] dark:border-gray-700 font-inter font-medium text-gray-900 dark:text-cobalt-500 py-6 w-full flex">
      <Section spacing="mt-4 md:mt-12 mb-3 px-4" className="w-full">
        <div className="flex flex-col md:flex-row w-full">
          <figure className="shrink-0 py-1 px-3 md:px-0 text-gray-300 w-full md:w-auto mb-8 mr-24 dark:text-bliss dark:opacity-80">
            <Logo color={false} />
          </figure>

          <nav className="w-full grid grid-cols-2 sm:grid-cols-4 mb-8">
            <List>
              <ListTitle>Product</ListTitle>
              <ul>
                {PRODUCT_LISTS.flatMap((category, c) =>
                  category.items.map((item, i) => (
                    <ListItem key={`id-${c}-${i}`} href={item.href}>
                      {item.label}
                    </ListItem>
                  )),
                )}
              </ul>
            </List>

            <List>
              <ListTitle>Connections</ListTitle>
              <ul>
                {CONNECTIONS.map((item, i) => (
                  <ListItem asPath={item.asPath} href={item.href} key={i}>
                    {item.title}
                  </ListItem>
                ))}
              </ul>
            </List>

            <List>
              <ListTitle>Resources</ListTitle>
              <ul className="mb-8">
                {FOOTER_RESOURCES_LIST.map((item, i) => (
                  <ListItem key={i} href={item.href}>
                    {item.label}
                  </ListItem>
                ))}
              </ul>
            </List>

            <List>
              <ListTitle>Company</ListTitle>
              <ul>
                {COMPANY_LINKS.map((item, i) =>
                  item.href ? (
                    <ListItem href={item.href} key={i}>
                      {item.label}
                    </ListItem>
                  ) : (
                    <ListItem onClick={item.onClick} key={i}>
                      {item.label}
                    </ListItem>
                  ),
                )}
              </ul>
            </List>
          </nav>
        </div>
        <div className="text-sm w-full mt-4 pt-4 flex gap-4 md:gap-14 items-center border-t-[0.5px] dark:border-gray-700">
          <div className="py-1">
            &copy; Timescale, Inc. {new Date().getFullYear()}
          </div>

          <ul className="flex items-center gap-2">
            <li>
              <a
                target="_blank"
                href="https://twitter.com/popsql"
                rel="noopener noreferrer"
              >
                <Twitter width="24px" height="24px" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://linkedin.com/company/popsql"
                rel="noopener noreferrer"
              >
                <Linkedin width="24px" height="24px" />
              </a>
            </li>
          </ul>
        </div>
      </Section>
    </footer>
  );
};
export default FooterNew;
